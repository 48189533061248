import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.individual_edit,
  },
  {
    id: 'personal_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address_2',
          nested: 'address',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'city',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.country,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: 'fiscal_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'primary_fiscal_residence',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.country,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'us_person',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.radio,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'has_secondary_fiscal_residence',
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          type: FieldTypeEnum.radio,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'secondary_fiscal_residence',
          isRequired: false,
          isEnabled: true,
          hasHelper: true,
          type: FieldTypeEnum.country,
          isMulti: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'employment_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'profession',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.select,
          options: [
            'legal_representative_director',
            'cfo_finance_controller_accountant_treasurer',
            'other_executive_or_senior_management_position',
            'regulated_independent',
            'government_or_public_institution_employee',
            'position_in_a_nonprofit_or_religious_organization',
            'business_owner_entrepreneur_or_other_selfemployed_position',
            'others',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'other_profession',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.text,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'has_risky_activity_sector',
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          type: FieldTypeEnum.select,
          options: [
            'no',
            'adult_content_industry',
            'airsoft_and_replica_weapon_sales',
            'arts_crafts_luxury_watches_and_jewelry',
            'auction_sector',
            'cannabis_and_drugrelated_activities',
            'construction_building_public_works',
            'criminal_fines_and_debt_financing',
            'crypto_sector',
            'energy_efficiency_renovation',
            'extractive_heavy_and_manufacturing_industries',
            'financial_management_by_unregulated_companies',
            'forex_trading_transferts_crowdfunding',
            'gambling_sector_casinos_online_games',
            'hacking_tools_and_counterfeit_sales',
            'health_and_pharmaceutical_activities',
            'hospitality_hotels_bars_restaurants',
            'liberal_and_regulated_professions',
            'military_and_defense_activities',
            'offshore_company_creation',
            'precious_metals_and_gems_trade',
            'professional_training_organizations',
            'psychic_services_and_fortunetelling',
            'real_estate_excluding_construction',
            'social_media_manipulation_services',
            'sports_and_esports_promotion',
            'tobacco_and_ecigarette_activities',
            'transport_and_logistics',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'source_of_funds',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.select,
          options: [
            'salary',
            'social_benefits_pension_or_others',
            'rental_income',
            'income_from_the_sale_of_real_estate',
            'interests_and_dividends',
            'income_from_inheritance',
            'others',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'business_relationship',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'iban',
          nested: 'banking_information',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.iban,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'bic',
          nested: 'banking_information',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.bic,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
];
